2<template>
  <v-app>
    <vue-particles
      color="#240b36"
      :particleOpacity="0.7"
      :particlesNumber="50"
      shapeType="star"
      :particleSize="4"
      linesColor="#c31432"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles>
    <v-container class="container">
      <v-card class="grid" :elevation="11" width="95%">
        <v-row align="center" class="d-flex align-center">
          <v-col :cols="4" align="center">
            <v-img
              src="../assets/images/logo.png"
              class="logo"
              alt="keretasewa"
            />
          </v-col>
        </v-row>
        <v-row align="center" class="d-flex align-center">
          <v-col :cols="4" align="center">
            <v-btn
              class="btnWa"
              @click="$router.push({ path: '/' })"
              ><v-icon
                style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053) !important;
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
              >
                mdi-home-circle-outline mdi-18px </v-icon
              >Home</v-btn
            >
          </v-col>
        </v-row>
        <v-container fluid fill-height v-if="showPhone">
          <v-row>
            <v-col md="6" cols="12">
              <v-card-text class="text">Mobile Number</v-card-text>
              <vue-phone-number-input
                v-model="mobileNumber"
                :rules="[rules.required]"
                required
                dense
                solo
                dark
                default-country-code="MY"
                @update="results = $event"
                :success="results?.isValid"
                style="height: 38px !important; margin-bottom: 5% !important"
              />
              <v-row
                justify="center"
                align="center"
                style="height: 61px !important"
              >
                <v-btn class="btnWa" @click="sendVerificationCode"
                  >Send Verification Code
                </v-btn></v-row
              >
            </v-col>
            <v-col cols="12" md="6" v-if="showInputOTP">
              <v-card-text class="text">Input OTP sent on Whatsapp</v-card-text>
              <v-text-field
                dark
                v-model="otp"
                :rules="[rules.required]"
                required
                class="text"
                dense
                solo
                style="height: 38px !important; margin-bottom: 5% !important"
              />
              <v-row
                justify="center"
                align="center"
                style="height: 61px !important"
              >
                <v-btn @click="verifyVerificationCode" class="btnWa"
                  >Verify OTP</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
         
        </v-container>
        <div v-if="changePasswordForm">
            <v-row align="center" class="d-flex align-center">
              <v-col :cols="4" align="center" style="padding: 0 !important">
                <v-card-text class="text2"
                  >Welcome {{ name }} , complete the steps below to reset your
                  password.</v-card-text
                >
                <v-stepper v-model="step">
                  <v-stepper-header>
                    <v-stepper-step :complete="step > 1" step="1">
                      Step 1
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="step > 2" step="2">
                      Step 2
                    </v-stepper-step>
                  </v-stepper-header>

                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-card
                        class="grid"
                        height="fit-content"
                        style="padding: 0 !important"
                      >
                        <v-card-text class="text"
                          >Enter your IC/Passport Number for
                          verification</v-card-text
                        >
                        <v-text-field
                          style="width: 80%"
                          dark
                          v-model="inputId"
                          required
                          :rules="[rules.required]"
                          dense
                          class="text"
                          solo
                        ></v-text-field
                      ></v-card>
                      <v-row class="justify-center align-center" no-gutters>
                        <v-btn
                          @click="verifyID"
                          class="btnWa"
                          style="
                            margin-top: 2% !important;
                            margin-bottom: 2% !important;
                          "
                        >
                          Continue
                        </v-btn>
                      </v-row>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                      <v-card
                        class="grid"
                        height="fit-content"
                        style="padding: 0 !important"
                      >
                        <v-row align="center" class="d-flex align-center">
                          <v-col :cols="4" style="padding: 10px !important">
                            <v-card-text class="text">New Password</v-card-text>
                            <v-text-field
                              dark
                              v-model="password.newPassword"
                              required
                              dense
                              class="text"
                              solo
                              :append-icon="
                                value ? 'visibility' : 'visibility_off'
                              "
                              @click:append="() => (value = !value)"
                              :type="value ? 'password' : 'text'"
                            ></v-text-field>
                          </v-col>
                          <v-col :cols="4" style="padding: 10px !important">
                            <v-card-text class="text"
                              >Confirm New Password</v-card-text
                            >
                            <v-text-field
                              dark
                              v-model="password.confirmNewPassword"
                              required
                              dense
                              class="text"
                              solo
                              :append-icon="
                                value ? 'visibility' : 'visibility_off'
                              "
                              @click:append="() => (value = !value)"
                              :type="value ? 'password' : 'text'"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-row class="justify-center align-center" no-gutters>
                        <v-btn
                          @click="changePassword"
                          style="
                            margin-top: 2% !important;
                            margin-bottom: 2% !important;
                          "
                          class="btnWa"
                        >
                          Change Password
                        </v-btn>
                      </v-row>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-col>
            </v-row>
          </div>
      </v-card>
      <v-footer padless class="footer" color="transparent">
      <v-card-text class="d-flex flex-column align-top footer">
        <div class="footer-top mb-3">
          <v-row align="center" class="row2">
            <v-col>
              <span
                class="mr-3 d-grid align-center justify-center"
                style="text-align: justify"
              >
                <a
                  href="https://jrvservices.co/"
                  target="_blank"
                  class="text2 text-decoration-none"
                >
                  <v-card-text class="d-flex align-center justify-center text2"
                    >JRV Car Rental Seremban | JRV Kereta Sewa Seremban</v-card-text
                  >
                </a>
              </span>
            </v-col>
          </v-row>
          <v-row align="center" class="row2">
            <v-col>
              <a
                href="https://www.facebook.com/profile.php?id=100095115500120"
                target="_blank"
              >
                <v-icon
                  size="32"
                  class="mx-2 btnWa"
                  style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  @click="trackButtonClick('FacebookGroup')"
                  >mdi-facebook</v-icon
                >
              </a>
              <a
                href="https://www.instagram.com/jrv_keretasewa/"
                target="_blank"
              >
                <v-icon
                  size="32"
                  class="mx-2 btnWa"
                  style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  @click="trackButtonClick('InstagramGroup')"
                  >mdi-instagram</v-icon
                >
              </a>
              <a
                href="https://chat.whatsapp.com/DVZXvnBiDqS0Z9jkHwL9yT"
                target="_blank"
              >
                <v-icon
                  size="32"
                  class="mx-2 btnWa"
                  style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  @click="trackButtonClick('WhatsappGroup')"
                  >mdi-whatsapp</v-icon
                >
              </a>
              <a href="https://t.me/+ILGvKb39imRkMDNl" target="_blank">
                <v-icon
                  size="32"
                  class="mx-2 btnWa"
                  style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
                  @click="trackButtonClick('TelegramGroup')"
                  >mdi-telegram</v-icon
                >
              </a>
            </v-col>
          </v-row>
        </div>
        <div
          class="footer-bottom text2"
          style="margin-top: 1% !important ; margin-bottom: 1% !important"
        >
          Copyright {{ new Date().getFullYear() }} @
          <a
            href="https://www.linkedin.com/in/rajveenz2/"
            target="_blank"
            class="company-link"
            style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
            >Fast Track Solutions</a
          >
        </div>
      </v-card-text>
    </v-footer>
    </v-container>
  </v-app>
</template>
<script>
import DataService from "../services/dataService.js";
const dataService = new DataService();

export default {
  data() {
    return {
      showPhone: true,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
      results: [],
      showInputOTP: false,
      otp: "",
      changePasswordForm: false,
      mobileNumber: "",
      step: 1,
      name: "",
      id: "",
      inputId: "",
      userId: "",
      password: {},
      value: String,
    };
  },

  methods: {
    async sendVerificationCode() {
      try {
        this.$setLoader();
        await dataService
          .sendVerificationCode({
            mobileNumber: this.results.formattedNumber,
            reset: true,
          })
          .then((res) => {
            if (res.data.verify) {
              this.showInputOTP = true;
            }
          });
        this.$disableLoader();
      } catch (error) {
        // alert("Error 1000 - Page does not exists!")
        this.$disableLoader();
      }
    },

    async verifyVerificationCode() {
      this.$setLoader();
      await dataService
        .verifyVerificationCode({
          mobileNumber: this.results.formattedNumber,
          otp: this.otp,
          reset: true,
        })
        .then((res) => {
          console.log(res.data.verify);
          if (res.data.verify.status == "approved") {
            // alert("You may enter a new password below.");
            this.changePasswordForm = true;
            this.showPhone = false;
            this.name = res.data.verify.name;
            this.id = res.data.verify.id;
            this.userId = res.data.verify._id;
            this.$disableLoader();
          } else {
            alert("Wrong OTP, retry or change number.");
            this.$disableLoader();
          }
        });
    },

    verifyID() {
      if (this.inputId) {
        if (this.inputId == this.id) {
          this.step = 2;
        } else {
          alert("Wrong ID entered, please retry");
        }
      }
    },

    async changePassword() {
      try {
        this.$setLoader();
        await dataService
          .resetPassword({
            _id: this.userId,
            password: this.password,
          })
          .then((res) => {
            if (res.data.reset == "Success") {
              alert("Password changed successfully");
              this.$router.push({ name: "login" });
            } else {
              alert("Password change failed, please retry");
              window.location.reload();
            }
          });
        this.$disableLoader();
      } catch (error) {
        // alert("Error 1000 - Page does not exists!")
        this.$disableLoader();
      }
    },
  },
};
</script>
<style scoped lang="css">
.container {
  max-width: none !important;
}
</style>
